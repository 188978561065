import { v4 as uuid_v4 } from "uuid";
import AmuzeMeIcon from "../assets/images/projects/amuze-me/icon.svg";

const amuzeMeVideo = require("../assets/images/projects/amuze-me/video.mp4");
const amuzeMeImg1 = require("../assets/images/projects/amuze-me/main.png");
const amuzeMeImg2 = require("../assets/images/projects/amuze-me/img1.png");
const amuzeMeImg3 = require("../assets/images/projects/amuze-me/img2.png");
const amuzeMeImg4 = require("../assets/images/projects/amuze-me/img3.png");
const amuzeMeImg5 = require("../assets/images/projects/amuze-me/img4.png");
const amuzeMeImg6 = require("../assets/images/projects/amuze-me/img5.png");

const blockrIcon = require("../assets/images/projects/blockr/icon.png");
const blockrImg1 = require("../assets/images/projects/blockr/main.png");
const blockrImg2 = require("../assets/images/projects/blockr/img1.png");
const blockrImg3 = require("../assets/images/projects/blockr/img2.png");
const blockrImg4 = require("../assets/images/projects/blockr/img3.png");
const blockrImg5 = require("../assets/images/projects/blockr/img4.png");

const staffingIcon = require("../assets/images/projects/staffing-panel/icon.png");
const staffingImg1 = require("../assets/images/projects/staffing-panel/main.png");
const staffingImg2 = require("../assets/images/projects/staffing-panel/img1.png");
const staffingImg3 = require("../assets/images/projects/staffing-panel/img2.png");
const staffingImg4 = require("../assets/images/projects/staffing-panel/img3.png");
const staffingImg5 = require("../assets/images/projects/staffing-panel/img4.png");
const staffingImg6 = require("../assets/images/projects/staffing-panel/img5.png");

const projectData = [
  {
    id: uuid_v4(),
    slug: "amuze-me",
    title: "Amuze Me",
    icon: AmuzeMeIcon,
    image: [
      { img: amuzeMeImg1, alt: "Amuze Me landing page" },
      {
        img: amuzeMeImg2,
        alt: "Taking a selfie using Amuze Me to get my mood",
        index: 0,
      },
      {
        img: amuzeMeImg3,
        alt: "Inputing text, using Amuze me to get my mood",
        index: 1,
      },
      {
        img: amuzeMeImg4,
        alt: "Rorschach ink blot image for text analysis on Amuze Me",
        index: 1,
      },
      { img: amuzeMeImg5, alt: "Joy playlist generated by Amuze Me", index: 2 },
      {
        img: amuzeMeImg6,
        alt: "Saved playlist history for Amuze Me",
        index: 2,
      },
    ],
    status: 1,
    desc: "Amuze Me is a fun interactive web application which utilizes Microsoft's Face AI and IBM Watson's Tone Analyzer to obtain a user's mood, and based off of that mood, a uniquely generated playlist is created.",
    details: [
      "Amuze Me was a capstone project which I had spent two weeks creating. Feel free to check out the demo video in the link above, or try the application out for yourself, also in the link above! With my psychology background in mind, I wanted to create something that could help alleviate stress and anxiety, and what better way to do that than by listening to music! I threw together the Microsoft Face AI API, with Spotify, and IBM Watson’s Tone Analyzer, and together, Amuze Me was created.",
      "This application uses React, Node, Express, Firebase and OAuth. This was definitely my greatest feat with regards to creating projects, as this was my first project where I had time dedicated to bringing this project to life rather than doing it intermittently between jobs, school, etc. The user starts by logging into their Spotify account, and then they can take some selfies, or use text input to obtain a mood through the Microsoft API, or IBM’s, respectively. Following the mood acquisition, the app creates a unique playlist based off of that mood and the user is able to save the playlist and listen to it.",
      "I hopefully aim to improve this application by converting it into a React Native application for use on both iOS and Android devices sometime in the future. But for the time being, check out the web app.",
    ],
    stack: [
      { id: uuid_v4(), type: "React" },
      { id: uuid_v4(), type: "NodeJS" },
      { id: uuid_v4(), type: "Express" },
      { id: uuid_v4(), type: "Firebase" },
    ],
    start: new Date("2020-06-08").getTime(),
    end: new Date("2020-06-23").getTime(),
    date: "June 2020",
    link: "https://amuze.jevel.io/",
    repo: "https://github.com/Especially/amuzeme",
    video: amuzeMeVideo,
  },
  {
    id: uuid_v4(),
    slug: "blockr",
    title: "Blockr",
    icon: blockrIcon,
    image: [
      { img: blockrImg1, alt: "Blockr Visitor Links page, showing user data" },
      {
        img: blockrImg2,
        alt: "Blockr projects at a glance with stats of unique and return visits",
        index: 0,
      },
      { img: blockrImg3, alt: "Blockr analytics graph", index: 0 },
      { img: blockrImg4, alt: "Blockr analytics user data", index: 2 },
      { img: blockrImg5, alt: "Blockr project settings", index: 1 },
    ],
    status: 0,
    desc: "Blockr is a web application dedicated to blogging platforms which aims to provide the user with analytics based on page views. But most importantly, it allows you to block users if they seem to be abusing your platform.",
    details: [
      "Blockr was a fun project that I decided to make, simply because I was on blogging platforms a lot. “What is it,” you may ask? Well at its core, it’s an analytics web application, which uses a small snippet of JavaScript on your page in order to obtain user information. With blogging platforms such as Blogspot, Tumblr, and WordPress in mind, it allows you to track where your users come from, where they’re going on your site, and most importantly, it allows you to block users.",
      "While the use is fairly simple, it has quite the stack behind it: PHP, HTML, SQL, Redis, Node, Express, Sockets, and of course JavaScript/jQuery. To no surprise, it uses SQL to store the user data, and Redis caches user instances in order to know when there has been a unique visit as opposed to a return visit, which ultimately allows for more accurate push notifications through Socket IO as well as email notifications when specified users are on your site. The application allows you to label users’ IP addresses in order to recall who may be viewing your page, and through JavaScript, if specified, the user can block, alert or hide their site from certain viewers, as well as enable or disable notifications. PHP allows us to obtain a plethora of information from a user, which is why I had opted to use this as the tracer API, guised as a .js file.",
      "The current application allows you to see the user’s OS, the browser that they’re using, their screen resolution, and which country they’re from. I can’t wait to improve this application and add all of the additional features that I have in mind, stay tuned.",
    ],
    stack: [
      { id: uuid_v4(), type: "Linux" },
      { id: uuid_v4(), type: "Apache" },
      { id: uuid_v4(), type: "MySQL" },
      { id: uuid_v4(), type: "PHP" },
      { id: uuid_v4(), type: "Redis" },
    ],
    start: new Date("2015-04-23").getTime(),
    end: new Date("2018-03-18").getTime(),
    link: "http://blockr.ca/",
    repo: "https://github.com/Especially/blockr",
    video: null,
  },
  {
    id: uuid_v4(),
    slug: "staffing-panel",
    title: "Staffing Panel",
    icon: staffingIcon,
    image: [
      {
        img: staffingImg1,
        alt: "Staffing Panel landing page, displaying payroll widget",
      },
      { img: staffingImg2, alt: "Staffing Panel new shift", index: 0 },
      { img: staffingImg3, alt: "Staffing Panel unfilled shifts", index: 0 },
      {
        img: staffingImg4,
        alt: "Staffing Panel Who's Available widget",
        index: 2,
      },
      { img: staffingImg5, alt: "Staffing Panel Payroll widget", index: 1 },
      { img: staffingImg6, alt: "Staffing Panel employee profile", index: 1 },
    ],
    status: 1,
    desc: "Small businesses often don't have the ability to pay for SaaS applications, and so I made it my duty to create a web application which allows nursing agencies to perform tasks in a quick and efficient way without cost.",
    details: [
      "Working for a small business taught me that not everyone has the resources available to them in order to work both effectively and efficiently. I decided to challenge myself while working for my father’s business and create an application that could convert tedious tasks into a quick fix that could be accessed anywhere, rather than simply in a notebook. Thus, was born the Staffing Panel.",
      "The web application is built heavily with PHP, HTML, JavaScript / jQuery, and is using SQL as the database. It allows the user to view all of their employees, all of the locations in which they service, and all of the shifts that they have, whether filled, or un-filled. What’d great about this application is that it also allows you to view the payroll for any specific pay period, and it also is able to generate invoices for the locations which they service.",
      "I view this project as a constant work in progress, as there are always ways to improve how things can be done with regards to their business structure, in a more efficient way. My next iteration of this project aims to include web sockets, a client-facing portal to submit shift requests with push notifications, and a bunch more features!",
    ],
    stack: [
      { id: uuid_v4(), type: "PHP" },
      { id: uuid_v4(), type: "MySQL" },
      { id: uuid_v4(), type: "HTML" },
      { id: uuid_v4(), type: "JavaScript" },
    ],
    start: new Date("2014-06-11").getTime(),
    end: new Date("2016-08-16").getTime(),
    link: "",
    repo: "https://github.com/Especially/staffing-panel",
    video: null,
  },
];

export default projectData;
